import { useMutation } from '@apollo/client';
import React from 'react';

import Button from 'components/Button/NewButton';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import { PATIENT_CONSENT_SUBMIT } from './ConsentAcceptAction.graphql';
import { ConsentAcceptActionButtonWrapper } from './ConsentAcceptAction.styled';
import {
  ConsentAcceptActionProps,
  PatientConsentSubmitResponseData,
  PatientConsentSubmitVariables,
  PatientFillableField
} from './ConsentAcceptAction.types';

export function ConsentAcceptAction({ consentId, buttonText, onAgree }: ConsentAcceptActionProps) {
  const [submitConsent, { loading }] = useMutation<
    PatientConsentSubmitResponseData,
    PatientConsentSubmitVariables
  >(PATIENT_CONSENT_SUBMIT, {
    onCompleted: (response: PatientConsentSubmitResponseData) => onDidAgree(response),
    onError: error => {
      BugTracker.notify(error, 'Failed to Sign Consent');
      showErrorToast(
        `Failed to sign the consent. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
      );
    }
  });

  const onDidAgree = (response: PatientConsentSubmitResponseData) => {
    if (!response?.createPatientConsent) return;
    onAgree(response.createPatientConsent.patientConsent.id);
  };

  const onSubmitConsent = () => {
    analytics.track(analytics.EVENTS.PATIENT_CONSENT_SIGN_ATTEMPTED);
    const patientFilledFields: PatientFillableField = collectPatientFilledFields();
    const variables: PatientConsentSubmitVariables = {
      consentId: consentId
    };
    if (Object.keys(patientFilledFields).length !== 0)
      variables.patientFilledFields = patientFilledFields;

    submitConsent({
      variables: variables
    });
  };

  return (
    <ConsentAcceptActionButtonWrapper>
      <Button text={buttonText} loading={loading} disabled={loading} onClick={onSubmitConsent} />
    </ConsentAcceptActionButtonWrapper>
  );
}

const collectPatientFilledFields = () => {
  const patientFilledFields: PatientFillableField = {};
  document
    .querySelectorAll('#consentContainer input, #consentContainer textarea')
    .forEach(fillableField => {
      const field = fillableField as HTMLInputElement;
      // BE expects all values to be strings
      patientFilledFields[field.id] =
        field.checked != null ? field.checked.toString() : field.value;
    });

  return patientFilledFields;
};

import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

interface ProtectedProps extends RouteComponentProps {
  children?: ReactNode;
}

// withProtectedRoute will check if user is logged in before
// rendering component or if they have a consent requirement
function withProtectedRoute(
  WrappedComponent: React.ComponentType<any>,
  options: { landingPage: 'login' | 'signup' } = { landingPage: 'login' }
) {
  return class Protected extends React.Component<ProtectedProps> {
    render() {
      return (
        <ProtectedRoute landingPage={options.landingPage}>
          <WrappedComponent {...this.props} />
        </ProtectedRoute>
      );
    }
  };
}

export default withProtectedRoute;

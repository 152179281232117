import React, { MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 100px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 48px;
  border: 2px solid ${themes.colors.neutral.primaryNavy};
`;

const ButtonText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  text-align: center;
`;

interface Props {
  text: string;
  ariaDescription?: string;
  onClick: (e: MouseEvent) => void;
}

const BorderedButton = (props: Props) => {
  return (
    <Button role="button" aria-describedby={props.ariaDescription} onClick={props.onClick}>
      <ButtonText>{props.text}</ButtonText>
    </Button>
  );
};

export default BorderedButton;

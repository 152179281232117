import React, { ReactNode } from 'react';
import Media from 'react-media';

import kindbody from 'assets/logos/kindbody.svg';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import Footer from '../components/Footer';
import QuoteImage, { WIDTH as QUOTE_IMAGE_WIDTH } from '../components/QuoteImage';

const { breakpoints } = themes;

// TODO: Remove this wrapper when all layouts will have the same structure
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  main,
  footer {
    @media (min-width: ${breakpoints.smallDesktop}) {
      margin-left: ${QUOTE_IMAGE_WIDTH};
    }
  }

  main {
    margin-bottom: 95px;
  }
`;

const ImageLink = styled.a`
  position: absolute;
  margin-top: 40px;
  margin-right: 24px;
  right: 0;
  top: 0;

  @media (min-width: ${breakpoints.smallDesktop}) {
    position: fixed;
  }
`;

const LeftImage = (props: { children: ReactNode }) => {
  return (
    <Media query={`(min-width: ${breakpoints.smallDesktop})`}>
      {desktop => (
        <Wrapper>
          <ImageLink href="https://kindbody.com/">
            <img src={kindbody} alt="kindbody" />
          </ImageLink>
          {desktop && <QuoteImage />}

          <main>{props.children}</main>
          <Footer />
        </Wrapper>
      )}
    </Media>
  );
};

export default LeftImage;

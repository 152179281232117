import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TextField } from 'components/v2/Form';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { ActionButtons } from 'screens/ActivateMembership/components/ActionButtons/ActionButtons';
import { Collate } from 'screens/ActivateMembership/components/Collate/Collate';
import { CollateType } from 'screens/ActivateMembership/components/Collate/Collate.types';
import { getGraphQLErrorsMessages } from 'utilities/errors';
import { showErrorToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import { VALIDATE_PATIENT_ENTERPRISE_MEMBERSHIP } from './UniqueIdScreen.graphql';
import { CollateContainer, Container, Form } from './UniqueIdScreen.styled';
import { ValidateMembershipResponse, ValidateMembershipVariables } from './UniqueIdScreen.types';

export const UniqueIdScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const [uniqueId, setUniqueId] = useState('');
  const { code } = location?.state ?? { code: null };
  const { eligibilityValidationFailed } = location?.state ?? { eligibilityValidationFailed: false };

  const [validateMembership, { loading }] = useMutation<
    ValidateMembershipResponse,
    ValidateMembershipVariables
  >(VALIDATE_PATIENT_ENTERPRISE_MEMBERSHIP, {
    onError: error => showErrorToast(getGraphQLErrorsMessages(error).join(', ')),
    onCompleted: () =>
      history.push(RoutesPath.CONFIRM_MEMBERSHIP_ACTIVATION, {
        code: code,
        uniqueId: uniqueId
      })
  });

  if (!code) {
    history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
  }

  const onValidateMembership = async () => {
    validateMembership({
      variables: {
        employerAccessCode: code,
        employeeAccessCode: uniqueId
      }
    });
  };

  return (
    <StepTrackerLayout
      title={'Verify my Eligibility'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            {eligibilityValidationFailed && (
              <CollateContainer>
                <Collate
                  type={CollateType.ERROR}
                  title="We need additional information to verify your eligibility."
                  description="We kindly request you input your Unique User ID as provided by your employer in the
                designated field below. Thank you for your cooperation."
                />
              </CollateContainer>
            )}
            <Form>
              <TextField
                type="text"
                status="default"
                id="input-unique-id"
                placeholder="Enter your unique ID"
                label="UNIQUE ID"
                value={uniqueId}
                onChange={event => setUniqueId(event.currentTarget.value)}
              />
            </Form>
            <ActionButtons
              onBack={() => history.goBack()}
              onForward={onValidateMembership}
              forwardDisabled={!uniqueId || loading}
              forwardText="Validate"
            />
          </Container>
        );
      }}
    />
  );
};

export default UniqueIdScreen;

import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { OutlineButton } from 'components/Buttons';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import Modal from 'components/Modal';
import SelectInput from 'components/SelectInput';
import TextInput from 'components/TextInput/NewTextInput';
import { Checkbox } from 'components/v2/Inputs';
import { assureProperCentRounding } from 'utilities';
import { analytics } from 'utilities/analytics';
import { USDollar } from 'utilities/currencyFormat';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { useBreakpoints } from 'utilities/useBreakpoints';

import useCreatePayment from '../../hooks/use-create-payment';
import * as Styled from '../InvoicePayModal/InvoicePayModal.styled';
import { PaymentDetails } from '../InvoicePayModal/PaymentDetails';
import { SelectReasonFlexContainer } from './PayCycleModal.styled';

export const PayCycleModal = ({
  open,
  onClose,
  onPaid
}: {
  open: boolean;
  onClose: () => void;
  onPaid: () => void;
}) => {
  const [loading, setloading] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const { isMobile } = useBreakpoints();
  const {
    createPaymentWithCreditCardNumber,
    createPaymentWithStripeIdentifier
  } = useCreatePayment();
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);

  useEffect(() => {
    setFormData(INITIAL_FORM_DATA);
    setStripeContext(undefined);

    if (open) {
      analytics.track(analytics.EVENTS.PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_LOADED);
    }
  }, [open]);

  const onSuccessPayment = () => {
    const price = USDollar.format(parseFloat(formData.amount));
    showSuccessToast(
      `You've successfully made your ${price} 
        payment! Please allow 5-7 business days for the total balance to reflect the payment just made.`
    );

    setloading(false);
    onPaid();
  };

  const onErrorPayment = (errorMessage: String) => {
    showErrorToast(errorMessage || DEFAULT_ERROR_MESSAGE);
    setloading(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setloading(true);

    if (stripeContext) {
      const params = {
        stripe: stripeContext.stripe,
        cardNumberElement: stripeContext.cardNumberElement,
        amountCents: parseFloat(formData.amount) * 100,
        reason: formData.reason?.value,
        reasonDetails: formData.reasonOther
      };

      createPaymentWithCreditCardNumber(params, onSuccessPayment, onErrorPayment);
    }

    if (kbStripeCardStripeIdentifierSelected) {
      const params = {
        kbStripeCardStripeIdentifier: kbStripeCardStripeIdentifierSelected,
        amountCents: parseFloat(formData.amount) * 100,
        reason: formData.reason?.value,
        reasonDetails: formData.reasonOther
      };

      createPaymentWithStripeIdentifier(params, onSuccessPayment, onErrorPayment);
    }

    analytics.track(analytics.EVENTS.PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_SUCCESSFUL);
  };

  const updateFormData = (field: string, event: any) => {
    let value;

    if (field === 'reason') {
      value = event;
    } else {
      value = event.currentTarget.value;
    }

    if (field === 'amount' && !Number.isNaN(parseFloat(value))) {
      if (value < 0) {
        value = undefined;
      } else {
        value = assureProperCentRounding(value);
      }
    }

    setFormData({ ...formData, [field]: value });
  };

  const toggleConfirmCheckbox = () => {
    setFormData({ ...formData, confirmed: !formData.confirmed });
  };

  const submitButtonIsDisabled =
    loading ||
    !formData.confirmed ||
    !formData.amount ||
    !inputValid ||
    (!stripeContext && kbStripeCardStripeIdentifierSelected === '');
  return (
    <Modal label="Make payment" open={open} onRequestClose={onClose} mobileFullscreen>
      <Styled.Form onSubmit={onSubmit}>
        {isMobile ? (
          <Styled.CloseArrowButton type="button" onClick={onClose}>
            Your invoices
          </Styled.CloseArrowButton>
        ) : (
          <Styled.CloseIconButton type="button" onClick={onClose} aria-label="Close modal">
            <CloseIcon />
          </Styled.CloseIconButton>
        )}

        <Styled.Header>Make payment</Styled.Header>
        <Styled.FlexContainer>
          <TextInput
            inputId="amount-input"
            name="amount"
            label="AMOUNT ($)"
            value={formData.amount}
            spellCheck="false"
            placeholder={'0.00'}
            type="number"
            max={10000}
            step={0.01}
            onChange={event => updateFormData('amount', event)}
          />
        </Styled.FlexContainer>
        <SelectReasonFlexContainer>
          <SelectInput
            label="REASON"
            ariaLabelledby="reason-select"
            extractTitle={item => (item ? item.label : '')}
            placeholder="Select"
            selectedOption={formData.reason}
            onSelect={event => updateFormData('reason', event)}
            options={REASON_OPTIONS}
            inputId="reason-select-id"
          />
        </SelectReasonFlexContainer>

        <Styled.PaymentSection>
          <Styled.PaymentHeader>Payment Details</Styled.PaymentHeader>

          <PaymentDetails
            onSelectCreditCard={data => {
              setKbStripeCardStripeIdentifier(data);
            }}
            onStripeCardElementInitialized={(
              stripe: Stripe,
              cardNumberElement: StripeCardNumberElement
            ) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onValidation={valid => setInputValid(valid)}
          />
        </Styled.PaymentSection>

        <Styled.FlexContainer>
          <Checkbox
            label="I understand that any credit resulting from this payment may be applied to a past due balance."
            isChecked={formData.confirmed}
            onChange={() => toggleConfirmCheckbox()}
            id="checkbox-confirm-understanding"
          />
        </Styled.FlexContainer>
        <Styled.FormActions>
          <Styled.PayButton type="submit" disabled={submitButtonIsDisabled} text="PAY" />
          <OutlineButton type="button" onClick={onClose}>
            Cancel
          </OutlineButton>
        </Styled.FormActions>
      </Styled.Form>
    </Modal>
  );
};

const REASON_OPTIONS = [
  { label: 'Cycle Payment', value: 'cycle_payment' },
  { label: 'Kindbody Benefit Payment', value: 'employer patient payment' }
];

interface FormDataValues {
  amount: string;
  reason: typeof REASON_OPTIONS[number];
  reasonOther: string;
  confirmed: boolean;
}

const INITIAL_FORM_DATA: FormDataValues = {
  amount: '',
  reason: REASON_OPTIONS[0],
  reasonOther: '',
  confirmed: false
};

const DEFAULT_ERROR_MESSAGE =
  "We're sorry but we could not process your payment. Please try again.";

import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from 'kb-redux';

import { GET_FEATURE_FLAGS } from './FeatureFlags.graphql';
import { FeatureFlags } from './FeatureFlags.types';
import { extractFeatureFlags } from './FeatureFlags.utils';
import FeatureFlagsContext from './FeatureFlagsContext';

export const FeatureFlagsProvider: FC = ({ children }) => {
  const isLoggedIn = useSelector<ReduxState>(state => state.patient.isLoggedIn);

  const { data } = useQuery(GET_FEATURE_FLAGS, { skip: !isLoggedIn });
  const featureFlags: Partial<FeatureFlags> = extractFeatureFlags(data?.featureFlags);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
  );
};

import styled from 'styled-components';

import Button from '../../../../components/Button/NewButton';

export const Form = styled.form`
  padding: 16px;

  ${({ theme }) => theme.fonts.DomaineDisplay};

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 20px 20px 32px 48px;
  }
`;

export const CloseArrowButton = styled.button`
  &::before {
    margin-right: 6px;
    content: '< ';
  }
`;

export const CloseIconButton = styled.button`
  display: block;
  margin-left: auto;

  svg path {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;

export const Header = styled.h1`
  margin: 15px 0px 22px 0px;

  font-size: 26px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 0px;
    font-size: 32px;
  }
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  button {
    margin-top: 8px;
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row-reverse;
    justify-content: flex-end;

    button {
      width: 180px;
      margin-right: 8px;
    }
  }
`;

export const InvoiceField = styled.div`
  margin-bottom: 16px;
  width: 100%;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: 50%;
    margin-bottom: 40px;
  }
`;

export const Label = styled.p`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  text-transform: uppercase;
`;

export const Value = styled.p`
  font-size: 24px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PayButton = styled(Button)`
  width: 100%;
`;

export const PaymentSection = styled.div`
  padding: 16px 8px 16px 8px;
  margin: 28px -8px 16px -8px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 16px 65px 16px 48px;
    margin: 0px -20px 16px -48px;
  }
`;

export const PaymentHeader = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 20px;
  line-height: 27px;
`;

import * as React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import useHomeMarket from 'hooks/useHomeMarket';
import { BookingNavigationTracker } from 'screens/Book/utils/BookingNavigationTracker';

import AppRoute from './Routes/AppRoute';
import { Routes } from './Routes/Routes';
import ScrollToTop from './ScrollToTop';

const Router = () => {
  const { vios: isViosPatient } = useHomeMarket();
  return (
    <BrowserRouter>
      <BookingNavigationTracker />
      <ScrollToTop />
      <Switch>
        {Routes.filter(route => !isViosPatient || !route.hiddenFromVios).map(route => {
          const { path, component, layout, page, ...rest } = route;
          return (
            <AppRoute
              exact
              key={path}
              path={path}
              component={component}
              layout={layout}
              page={page}
              {...rest}
            />
          );
        })}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

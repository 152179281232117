import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Heading, Text } from 'components/v2/Typography';
import { useHomeMarket } from 'hooks/useHomeMarket';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { Container, ThreeColumnGrid } from '../shared.styled';
import { CareTeamResponse, CARE_TEAM_QUERY } from './CareTeam.graphql';
import { hasRequiredLocationData } from './CareTeam.utils';
import { AssignedCareTeamPeople } from './components/AssignedCareTeamPeople';
import { PreferredLocation } from './components/PreferredLocation';
import { Header, Section } from './styled';

export const CareTeam = () => {
  const { name: homeMarketName, vios } = useHomeMarket();
  const { data, error } = useQuery<CareTeamResponse>(CARE_TEAM_QUERY);
  const shouldRenderPharmacyLocation = hasRequiredLocationData(data?.patientPharmacy);
  const shouldRenderLabLocation = hasRequiredLocationData(data?.patientLabCenter);
  const shouldRenderRadiologyLocation = hasRequiredLocationData(data?.patientRadiologyCenter);

  useEffect(() => {
    if (error) {
      BugTracker.notify(error, 'Failed to fetch care team information');
    }
  }, [error]);

  return (
    <Container>
      <Header>
        <Heading tag="span" styledAs="h2">
          Your Care Team
        </Heading>
        <Text size="sm">
          Your team of physicians, nurse practitioners, physician assistants, and endocrinologists
          caring for you.
        </Text>
      </Header>

      {homeMarketName && (
        <Header>
          <Heading tag="span" styledAs="h4">
            Your Home Market: {homeMarketName}
          </Heading>

          {vios ? (
            <Text tag="p">Need to change the home market? Send us a message!</Text>
          ) : (
            <Text tag="p" size="sm">
              Need to change the home market?{' '}
              <Text tag="span" transform="uppercase" color={theme.colors.blue.darkBlue}>
                <Link data-testid="change-home-market-link" to="/messages">
                  Send us a message!
                </Link>
              </Text>
            </Text>
          )}
        </Header>
      )}

      {data && (
        <>
          <ThreeColumnGrid>
            <AssignedCareTeamPeople careTeam={data.careTeam} />
          </ThreeColumnGrid>

          {(shouldRenderLabLocation ||
            shouldRenderPharmacyLocation ||
            shouldRenderRadiologyLocation) && (
            <Section>
              <Heading tag="span" styledAs="h4">
                Preferred Locations
              </Heading>
              <ThreeColumnGrid>
                {shouldRenderLabLocation && (
                  <PreferredLocation type="Preferred pharmacy" location={data.patientPharmacy} />
                )}
                {shouldRenderLabLocation && (
                  <PreferredLocation type="Preferred lab center" location={data.patientLabCenter} />
                )}
                {shouldRenderRadiologyLocation && (
                  <PreferredLocation
                    type="Preferred radiology center"
                    location={data.patientRadiologyCenter}
                  />
                )}
              </ThreeColumnGrid>
            </Section>
          )}
        </>
      )}
    </Container>
  );
};

import { StepType } from 'screens/Book/utils/BookingStateManager.types';

import { createURLSearchParams } from './urlUtils';

export enum RoutesPath {
  CHANGE_EMAIL = '/change-email',
  ENTER_MEMBERSHIP_ACCESS_CODE = '/activate-membership/code',
  ENTER_MEMBERSHIP_UNIQUE_ID = '/activate-membership/unique-id',
  VERIFY_MEMBERSHIP_ELIGIBILITY = '/activate-membership/verify-eligibility',
  CONFIRM_MEMBERSHIP_ACTIVATION = '/activate-membership/confirm',
  DOCUMENTS = '/documents',
  RESULTS = '/results',
  CONSENTS = '/consents',
  DASHBOARD = '/',
  CREATE_ACCOUNT = '/create-account',
  SIGN_UP = '/sign-up',
  APPOINTMENTS = '/appointments',
  APPOINTMENTS_V2 = '/appointments-v2',
  BOOK = '/book',
  ACTIVATE_MEMBERSHIP_WALMART_WIN_BID = '/activate-membership/walmart/win-or-bid',
  ACTIVATE_MEMBERSHIP_WALMART_CONFIRM_IDENTITY = '/activate-membership/walmart/confirm-your-identity',
  CONFIRMATION = '/confirmation',
  MY_JOURNEY = '/my-journey',
  LOGIN = '/login',
  LOGIN_SSO_EPIC = '/login-sso/epic-disney',
  LOGIN_SSO_MEDTRONIC = '/login-sso/azure-medtronic',
  LOGOUT = '/logout',
  FORGOT_PASSWORD = '/forgot-password',
  ACCESS_ACCOUNT = '/access-account',
  VERIFICATION_CODE = '/verification-code',
  MEDICATION_INSTRUCTIONS = '/medication-instructions',
  MEDICATION_INSTRUCTION = '/medication-instruction',
  MOBILE_OAUTH = '/mobile-oauth',
  MY_PROFILE = '/my-profile',
  BOOKING_QUIZ = '/booking-quiz',
  ACTIVATE_KINDBODY_BENEFIT = '/activate-kindbody-benefit',
  KINDBODY_BENEFIT = '/kindbody-benefit',
  INVOICES = '/invoices',
  RESOURCES = '/resources',
  KINDBODY_360 = '/kindbody/360',
  CARE_TEAM = '/care-team',
  CYCLE_PLAN = '/cycle-plan',
  PATIENT_INTAKE = '/patient-intake',
  VIOS_PAYMENT = '/vios-payment',
  VIRTUAL_MEETING = '/virtual-meeting',
  CONFIRM_NEW_EMAIL = '/confirm-new-email',
  MESSAGES = '/messages',
  DOCUMENTS_V2 = '/v2/documents'
}

export const pageUrl = {
  myProfile: (
    tabId?:
      | 'demographic'
      | 'contact'
      | 'partner-invitations'
      | 'insurance'
      | 'employer'
      | 'trustees'
      | 'care-team'
      | 'payment-options'
  ) => {
    if (tabId) {
      return `${RoutesPath.MY_PROFILE}?tab=${tabId}`;
    }

    return RoutesPath.MY_PROFILE;
  },
  booking: {
    index(parameters?: { step?: StepType; appointmentId?: string; timeSlotId?: string }) {
      const route = '/Book';

      if (!parameters) {
        return route;
      }

      const { step, appointmentId, timeSlotId } = parameters;

      const searchParameters = createURLSearchParams({
        step,
        appointment_id: appointmentId,
        time_slot_id: timeSlotId
      });

      return `${route}?${searchParameters}`;
    },
    bookingQuiz(params?: { type: 'question' | 'answer'; id: string | number }) {
      if (params?.type === 'question') {
        return `${RoutesPath.BOOKING_QUIZ}?q=${params.id}`;
      }

      if (params?.type === 'answer') {
        return `${RoutesPath.BOOKING_QUIZ}?a=${params.id}`;
      }

      return RoutesPath.BOOKING_QUIZ;
    },
    rescheduleAppointment(appointmentId: string) {
      return `/Book?step=location&reschedule_appointment_id=${appointmentId}`;
    },
    confirmation(
      params?:
        | { type: 'appointment'; purchasedProductId: string }
        | { type: 'membership'; confirmedMembershipId: string }
        | { type: 'enterprise_membership' }
    ) {
      if (!params) {
        return RoutesPath.CONFIRMATION;
      }

      if (params.type === 'appointment') {
        return `${RoutesPath.CONFIRMATION}?step=success&confirmed_appointment_id=${params.purchasedProductId}`;
      }

      if (params.type === 'membership') {
        return `${RoutesPath.CONFIRMATION}?step=success&confirmed_membership_id=${params.confirmedMembershipId}`;
      }

      if (params.type === 'enterprise_membership') {
        return `${RoutesPath.CONFIRMATION}?step=success&enterprise_membership=true`;
      }

      return RoutesPath.CONFIRMATION;
    }
  },

  messages: (urlParameters?: MessageUrlParameters) => {
    const categoryName = urlParameters?.categoryName;
    const conversationId = urlParameters?.conversationId;
    const subject = urlParameters?.subject;
    const message = urlParameters?.message;

    if (categoryName && conversationId) {
      return `${RoutesPath.MESSAGES}/${categoryName}/${conversationId}`;
    }

    if (categoryName && subject && message) {
      return `${RoutesPath.MESSAGES}/${categoryName}?subject=${encodeURIComponent(
        subject
      )}&message=${encodeURIComponent(message)}`;
    }

    if (categoryName && subject) {
      return `${RoutesPath.MESSAGES}/${categoryName}?subject=${encodeURIComponent(subject)}`;
    }

    if (categoryName) {
      return `${RoutesPath.MESSAGES}/${categoryName}`;
    }

    return RoutesPath.MESSAGES;
  },
  createAccount: (parameters?: {
    step?: StepType;
    previous?: string;
    appointmentId?: number | string;
    timeSlotId?: number | string;
  }) => {
    const route = RoutesPath.CREATE_ACCOUNT;

    if (!parameters) {
      return route;
    }

    const { step, previous, appointmentId, timeSlotId } = parameters;

    const searchParameters = createURLSearchParams({
      step,
      previous,
      appointment_id: appointmentId,
      time_slot_id: timeSlotId
    });

    return `${route}?${searchParameters}`;
  },
  intakeForm: (): string => {
    return RoutesPath.PATIENT_INTAKE;
  },
  login: (): string => {
    return RoutesPath.LOGIN;
  },
  emailAddressVerification: (): string => {
    return RoutesPath.VERIFICATION_CODE;
  },
  passwordReset: (): string => {
    return RoutesPath.FORGOT_PASSWORD;
  },
  enterpriseMembershipAccessCode: (): string => {
    return '/activate-membership/code';
  },
  documents: (): string => {
    return RoutesPath.DOCUMENTS;
  },
  results: (): string => {
    return RoutesPath.RESULTS;
  },
  consents: (id?: string | number): string => {
    if (id) {
      return `${RoutesPath.CONSENTS}/${id}`;
    }

    return RoutesPath.CONSENTS;
  },
  logout: (): string => {
    return RoutesPath.LOGOUT;
  },
  invoices: (urlParameters?: InvoiceUrlParameters) => {
    if (urlParameters?.tabId) {
      return `${RoutesPath.INVOICES}?tab=${urlParameters.tabId}`;
    }

    if (urlParameters?.invoiceIdentifier) {
      return `${RoutesPath.INVOICES}/${urlParameters.invoiceIdentifier}`;
    }

    return RoutesPath.INVOICES;
  },
  dashboard: (): string => {
    return RoutesPath.DASHBOARD;
  },
  myJourney: (): string => {
    return RoutesPath.MY_JOURNEY;
  },
  appointmentsV2: (): string => {
    return RoutesPath.APPOINTMENTS_V2;
  }
};

export interface MessageUrlParameters {
  categoryName?: string;
  conversationId?: string;
  subject?: string;
  message?: string;
}

export interface InvoiceUrlParameters {
  tabId?: 'unapplied-payments' | 'payments' | 'invoices';
  invoiceIdentifier?: string;
}

import styled from 'styled-components';

export const PaymentDetailsCreditCard = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
  flex-wrap: wrap;
  width: 100%;

  & > div:first-child {
    margin-bottom: 4px;
  }

  @media ${({ theme }) => theme.queries.maxTablet} {
    > button {
      flex: 1%;
    }
  }
`;

export const BaseCardContainer = styled.label`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  border-bottom: 1px solid;
`;

export const CardNumberContainer = styled(BaseCardContainer)`
  flex: 3;

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-basis: 100%;
  }
`;

export const CardExpirationContainer = styled(BaseCardContainer)`
  flex: 1;
`;

export const CardCvcContainer = styled(BaseCardContainer)`
  flex: 1;
`;

import React from 'react';
import { useLocation } from 'react-router-dom';

import { useInterstitialStatus } from 'hooks/useInterstitialStatus';
import usePatient from 'hooks/usePatient';
import { InterstitialModal } from 'screens/InterstitialModal/InterstitialModal';
import { PatientStateModal } from 'screens/PatientStateModal/PatientStateModal';

import {
  shouldOpenInterstitialModal,
  isOnConsentPage,
  shouldOpenPatientStateModal,
  isOnConfirmationPage
} from './InitialModal.utils';

export const InitialModal = () => {
  const location = useLocation();
  const { status, loading: interstitialStatusLoading } = useInterstitialStatus();
  const { patient, loading: patientLoading } = usePatient();

  const openInterstitialModal = status ? shouldOpenInterstitialModal(status) : false;
  const openPatientStateModal = shouldOpenPatientStateModal(patient);

  const isConsentPage = isOnConsentPage(status?.requiredConsents, location);
  const isConfirmationPage = isOnConfirmationPage(location);

  if (isConsentPage || isConfirmationPage) return <></>;

  if (openInterstitialModal && !interstitialStatusLoading) {
    return <InterstitialModal open={openInterstitialModal} />;
  }

  if (openPatientStateModal && !patientLoading) {
    return <PatientStateModal open={openPatientStateModal} />;
  }

  return <></>;
};

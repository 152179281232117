import { gql } from '@apollo/client';

// TODO: Utilize Fragments here - starting with a Clinic fragment

/**
 * Appointments available that the user can schedule
 */
export const APPOINTMENT_TYPES = gql`
  query appointmentTypes {
    appointmentTypes {
      id
      name
      virtual
      virtualFert
      description
      videoLink
      rateCents
      category {
        id
        name
      }
    }
  }
`;

export const PACKAGES = gql`
  query packages {
    packages {
      id
      name
      description
      rateCents
      discountRateCents: discountAmountCents
      quantity
      __typename
    }
  }
`;

export const CLINICS = gql`
  query clinics($appointmentTypeId: Int!) {
    clinics: locations(appointmentTypeId: $appointmentTypeId) {
      id
      name
      address
      timeZone
      patientFacing
    }
  }
`;

export const PATIENT = gql`
  query patient {
    patient {
      id
      cognitoId
      federatedId
      firstName
      lastName
      pronoun
      genderIdentity
      createdAt
      identifier
      authProviders
      mfaEnabled
      sendSmsAppointmentNotification
      sendMarketingEmail
      lab {
        id
        timeZone
        name
        vios
      }
      patientMembership {
        id
        membershipId
        employer
        employerPhone
        createdAt
        membership {
          name
          id
          documentUrl
          description
          employerId
          hidePartnerClinics
        }
        employerName
      }
      gender
      birthday
      email
      phone
      authComms
      confirmed
      preferredTimezone
      patientPharmacy {
        id
        name
        phone
      }
      address {
        id
        address1
        address2
        city
        state
        zipcode
        country
      }
      patientTrustees {
        id
        name
        phone
        state
        relationship
        email
        preferredContacts
      }
    }
  }
`;

export const PATIENT_NAME = gql`
  query patientName {
    patientName: patient {
      first: firstName
      last: lastName
    }
  }
`;

export const PATIENT_LAB = gql`
  query patientLab {
    patientLab: patient {
      id
      lab {
        id
      }
    }
  }
`;

/** Appointments that belong to the user */
export const PATIENT_APPOINTMENTS = gql`
  query appointments {
    appointments {
      id
      videoLink
      location {
        id
        address
        name
        patientFacing
        timeZone
      }
      startTime
      endTime
      timeZone
      cancelable
      reschedulable
      providerName
      appointmentType {
        id
        virtual
        virtualFert
        name
        description
        videoLink
        rateCents
        channel
        category {
          id
          name
        }
      }
    }
  }
`;

export const VALIDATE_APPOINTMENT_DISCOUNT = gql`
  query validateDiscount($discountCode: String!, $appointmentTypeId: Int) {
    validateDiscount: validateDiscount(
      discountCode: $discountCode
      appointmentTypeId: $appointmentTypeId
    ) {
      subtotalCents
      discountCents
      totalCents
    }
  }
`;

export const VALIDATE_PACKAGE_DISCOUNT = gql`
  query validateDiscount($discountCode: String!, $packageId: Int) {
    validateDiscount: validateDiscount(discountCode: $discountCode, packageId: $packageId) {
      subtotalCents
      discountCents
      totalCents
    }
  }
`;

export const VALIDATE_MEMBERSHIP_DISCOUNT = gql`
  query validateDiscount($discountCode: String!, $membershipId: Int) {
    validateDiscount: validateDiscount(discountCode: $discountCode, membershipId: $membershipId) {
      subtotalCents
      discountCents
      totalCents
    }
  }
`;

export const INTAKE_FORM = gql`
  query patientForm($formId: Int!, $appointmentType: String) {
    patientForm(formId: $formId) {
      id
      title
      formSections {
        title
        active
        type
        position
        formElements(appointment: $appointmentType) {
          id
          title
          type
          dataType
          options
          position
          required
        }
        fieldGroups {
          id
          formFields {
            id
            title
            type
            dataType
            options
            position
            required
          }
        }
      }
      conditionalFormElements {
        value
        conditionalFormElementId
        dependencyFormElementId
        logicOperator
      }
    }
    formCompletion(formId: $formId) {
      id
      percentageComplete
      answers {
        id
        data
        dataArray
        formElementId
        formElementTitle
      }
    }
  }
`;

export const FORM_COMPLETION_ANSWERS = gql`
  query formCompletion($formId: Int!) {
    formCompletion(formId: $formId) {
      id
      percentageComplete
      answers {
        id
        data
        dataArray
        formElementId
        formElementTitle
      }
    }
  }
`;

export const TODAYS_INSTRUCTIONS = gql`
  query todaysInstructions {
    todaysInstructions: dailyInstruction {
      date
      dayNumber
      updatedAt
      drugs {
        amount
        dailyRecurrence
        id
        name
        timeOfDay
      }
      id
      instructions
      acknowledged
      haveIntercourse
    }
  }
`;

export const NEXT_INSTRUCTIONS = gql`
  query nextInstructions {
    nextInstructions: dailyInstructions {
      acknowledged
      date
      dateLabel
      dayNumber
      haveIntercourse
      id
      instructions
      updatedAt
      drugs {
        amount
        dailyRecurrence
        id
        name
        timeOfDay
      }
    }
  }
`;

/**
 * Period tracking
 */

export const USER_PERIOD = gql`
  query lastPeriod {
    lastPeriod {
      periodDate
      nextPeriodDate
      daysBetweenCycles
      id
    }
  }
`;

export const LABS = gql`
  query labs {
    labs {
      id
      name
    }
  }
`;

export const QUERY_MEMBERSHIP = gql`
  query membership($membershipId: Int!) {
    membership(membershipId: $membershipId) {
      id
      documentUrl
      description
      employerId
      hidePartnerClinics
    }
  }
`;

export const GET_DROPDOWN_OPTIONS = gql`
  query getDropdownOptions($key: String!) {
    getDropdownOptions(key: $key) {
      label
      value
    }
  }
`;

export const TODOS = gql`
  query patientTodos {
    patientTodos {
      id
      todoId
      title: name
      linkUrl
      buttonText
      description
      completed
      order
      headingText
      category
      createdAt
    }
  }
`;

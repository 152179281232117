import React from 'react';

import { Clinic, TimeSlot, AppointmentType } from 'kb-shared';
import getAppointmentChannel from 'utilities/getAppointmentChannel';

import {
  AppointmentBlockLocation,
  AppointmentBlockLocationBold
} from './AppointmentDetailBlock.styled';

const AppointmentLocation = (props: {
  timeSlot: TimeSlot;
  appointmentType: AppointmentType;
  clinic: Clinic | null;
}) => {
  const { appointmentType, timeSlot, clinic } = props;
  const { isPhoneCall, isVideoCall } = getAppointmentChannel(appointmentType);
  if (isPhoneCall) {
    return (
      <AppointmentBlockLocation>
        <AppointmentBlockLocationBold>
          {'Your provider will call you at the selected time.'}
        </AppointmentBlockLocationBold>
      </AppointmentBlockLocation>
    );
  } else if (isVideoCall) {
    return (
      <AppointmentBlockLocation>
        <AppointmentBlockLocationBold>{'Video Consult'}</AppointmentBlockLocationBold>
        {timeSlot.videoLink || appointmentType.videoLink}
      </AppointmentBlockLocation>
    );
  } else if (clinic) {
    return (
      <AppointmentBlockLocation>
        <AppointmentBlockLocationBold>{clinic.name}</AppointmentBlockLocationBold>
        {clinic.address}
      </AppointmentBlockLocation>
    );
  } else {
    return null;
  }
};

export default AppointmentLocation;

import BaseModal from 'components/Modal/BaseModal';
import styled from 'styled-components';

const overlayClassName = 'styled-overlay-classname';
const modalClassName = 'styled-modal-classname';

interface StyledModalProps {
  mobileFullscreen?: boolean;
  fullScreen?: boolean;
  maxWidth?: number;
  addPadding?: boolean;
}

export const CustomReactModal = styled(BaseModal).attrs({
  overlayClassName,
  modalClassName
})<StyledModalProps>`
  .${overlayClassName} {
    position: fixed;
    z-index: 301;
    inset: 0px;
    height: 100%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 42, 94, 0.48);
  }

  .${modalClassName} {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 3px 0px rgba(67, 71, 146, 0.16);
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    height: auto;
    padding: 32px 24px;
    position: relative;

    @media ${({ theme }) => theme.queries.tablet} {
      padding: 48px 64px 64px 64px;
    }
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  font-size: 20px;
  margin: 8px;
  position: absolute;
  right: 2px;
  top: 18px;

  @media ${({ theme }) => theme.queries.tablet} {
    right: 42px;
    top: 34px;
  }
`;

export const Header = styled.div`
  > h6 {
    margin-right: 44px;
  }
`;

import React, { useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plusYellow.svg';
import { Loader } from 'components/Loader/Loader';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';
import { usePartnerInvites } from 'screens/InterstitialModal/hooks/usePartnerInvites';
import { PartnerInvite } from 'screens/InterstitialModal/InterstitialModal.types';

import { Container } from '../shared.styled';
import { InitialInviteAction } from './InitialInviteAction';
import { AddPartnerButtonContainer, VerticalWrapper } from './PartnerInvitations.styled';
import { PartnerInviteForm } from './PartnerInviteForm';
import { PartnerListItem } from './PartnerListItem';

export const PartnerInvitations = () => {
  const { invites, refetch, loading: invitesLoading } = usePartnerInvites();
  const [formActive, setFormActive] = useState(false);

  const { status } = useInterstitialStatus();

  if (invitesLoading) return <Loader absoluteCentered />;

  const hasInvites = invites?.length > 0;
  if (!hasInvites && !formActive)
    return (
      <InitialInviteAction
        partnerAlreadyOnRecord={status?.skipPartnerDataCapture ?? false}
        onClick={() => setFormActive(true)}
      />
    );

  return (
    <Container>
      <VerticalWrapper>
        {invites.map((invite: PartnerInvite) => (
          <PartnerListItem key={invite.id} invite={invite} />
        ))}
        {formActive && (
          <PartnerInviteForm
            onDone={() => {
              setFormActive(false);
              refetch();
            }}
          />
        )}
        {!formActive && (
          <AddPartnerButtonContainer>
            <LinkButton
              text="ADD NEW PARTNER"
              size="lg"
              underline="none"
              leftIcon={<PlusIcon />}
              onClick={() => setFormActive(true)}
            />
          </AddPartnerButtonContainer>
        )}
      </VerticalWrapper>
    </Container>
  );
};

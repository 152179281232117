import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};

  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  line-height: 12px;
  justify-content: center;
  width: 24px;
`;

import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { usePatient } from 'hooks';
import { checkAndSetConsent } from 'utilities/consent';
import { pageUrl } from 'utilities/pageUrl';
import { getPostLogoutLandingUrl } from 'utilities/userUtil';

type Props = {
  landingPage: 'login' | 'signup';
  children: JSX.Element;
};

export const ProtectedRoute = ({ children, landingPage = 'login' }: Props) => {
  const [redirected, setRedirected] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = usePatient();

  useEffect(() => {
    checkForConsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForConsent = () => {
    const consent = checkAndSetConsent();
    if (consent) {
      redirectToConsent(consent);
    }
  };

  const redirectToConsent = (consent: string) => {
    if (redirected || location.pathname.includes(`${pageUrl.consents()}/`)) return;
    setRedirected(true);
    history.replace(pageUrl.consents(consent));
  };

  if (isLoggedIn == null) return <Loader absoluteCentered />;

  if (!isLoggedIn) {
    const returnUrl =
      location.pathname === '/' ? null : encodeURIComponent(location.pathname + location.search);

    if (
      (location.pathname === '/book' && location.search.includes('membership_id')) ||
      location.search.includes('enterprise_membership')
    ) {
      if (returnUrl) {
        return <Redirect to={`/create-account?step=create_account&target=${returnUrl}`} />;
      }
      return <Redirect to={`/create-account`} />;
    }

    if (landingPage === 'login') {
      const postLogoutLandingUrl = getPostLogoutLandingUrl();
      if (postLogoutLandingUrl) {
        location.href = postLogoutLandingUrl;
        return null;
      }
      if (returnUrl) {
        return <Redirect to={`/login?target=${returnUrl}`} />;
      } else {
        return <Redirect to={`/login`} />;
      }
    } else {
      return <Redirect to={`/create-account?step=create_account&target=${returnUrl}`} />;
    }
  }

  return children;
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/v2/Typography';
import { ResponsiveContainer } from 'screens/styled_common';
import { pageUrl } from 'utilities/pageUrl';

import { BookingButton, HeaderContainer } from './Appointments.styled';

export const Appointments: React.FC = () => {
  const history = useHistory();

  return (
    <ResponsiveContainer>
      <HeaderContainer>
        <Heading tag="h1" noMargin>
          My Appointments
        </Heading>
        <BookingButton
          category="primary"
          label="Schedule Appointment"
          onClick={() => history.push(pageUrl.booking.bookingQuiz())}
          size="md"
        />
      </HeaderContainer>
    </ResponsiveContainer>
  );
};

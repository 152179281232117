import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';
import { VerificationCode } from 'components/VerificationCode/VerificationCode';
import { ReduxState } from 'kb-redux';
import { login } from 'kb-redux/user.redux';
import { showSuccessToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

export const EmailVerificationScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector<ReduxState>(state => state.patient.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) return;
    history.push('/');
  }, [history, isLoggedIn]);

  return (
    <AuthFlowLayout
      title="Enter Verification Code"
      renderMainContent={() => {
        return (
          <VerificationCode
            email={location?.state?.email}
            onSuccess={() => {
              const email = location?.state?.email;
              const password = location?.state?.password;

              if (email && password) {
                dispatch(login(email, password));
              } else {
                const forgotPassword = location?.state?.forgotPassword;
                if (forgotPassword) {
                  showSuccessToast(
                    'Thank you for verifying your account! You may now reset your password.'
                  );
                  history.push(pageUrl.passwordReset());
                } else {
                  showSuccessToast('Thank you for verifying your account! You may now log in.');
                  history.push('/');
                }
              }
            }}
          />
        );
      }}
    />
  );
};

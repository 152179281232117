import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import BorderedButton from 'components/BorderedButton';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import SelectInput from 'components/SelectInput';
import { stripeInputStyle } from 'components/TextInput/NewTextInput';
import { useCreditCards } from 'hooks/useCreditCards/useCreditCards';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { DropdownOption } from 'kb-shared/graphql/types';

import {
  CardCvcContainer,
  CardExpirationContainer,
  CardNumberContainer,
  PaymentDetailsCreditCard
} from './PaymentDetails.styled';
import { ElementsContentProps, Props } from './PaymentDetails.types';

export const PaymentDetails = ({
  onStripeCardElementInitialized,
  onSelectCreditCard,
  onValidation,
  alwaysShowNewCreditCardForm = false,
  paymentOptional,
  onCreditCardChange
}: Props): JSX.Element => {
  const [selectedCreditCard, setSelectedCreditCard] = useState<DropdownOption | null>(null);
  const [creditCardOptions, setCreditCardOptions] = useState<DropdownOption[]>([]);
  const { showNewCreditCardForm, setShowNewCreditCardForm } = useNewCreditCardForm(
    alwaysShowNewCreditCardForm
  );

  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const stripePublicKey = process.env.REACT_APP_KB_STRIPE_PLATFORM_ACCOUNT_PUBLIC_API_KEY;

  const { ppCreditCardsReuse } = useFeatureFlags();
  const { creditCards, creditCardsLoading } = useCreditCards();

  useEffect(() => {
    onValidation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stripePublicKey && stripePublicKey.length > 0)
      setStripePromise(loadStripe(stripePublicKey));
  }, [stripePublicKey]);

  useEffect(() => {
    const options = creditCards.map(card => ({
      label: card.lastFourDigits,
      value: card.stripeIdentifier
    }));
    setCreditCardOptions(options);

    const defaultCreditCard = creditCards.reduce<DropdownOption | null>((total, item) => {
      if (item.default) {
        return {
          label: item.lastFourDigits,
          value: item.stripeIdentifier
        };
      }

      return total;
    }, null);
    setSelectedCreditCard(defaultCreditCard);
    if (creditCards.length > 0) {
      setShowNewCreditCardForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCards]);

  useEffect(() => {
    if (!ppCreditCardsReuse || !onSelectCreditCard) {
      return;
    }

    if (selectedCreditCard) {
      setShowNewCreditCardForm(false);
      onValidation(true);
    }

    onSelectCreditCard(selectedCreditCard?.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCard, ppCreditCardsReuse]);

  useEffect(() => {
    if (showNewCreditCardForm) {
      setSelectedCreditCard(null);
      onValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNewCreditCardForm]);

  if (!stripePromise || creditCardsLoading) return <></>;

  return (
    <PaymentDetailsCreditCard>
      {showNewCreditCardForm ? (
        <Elements stripe={stripePromise}>
          <ElementsContent
            onStripeCardElementInitialized={onStripeCardElementInitialized}
            onValidation={onValidation}
            paymentOptional={paymentOptional}
            onCreditCardChange={onCreditCardChange}
          />
        </Elements>
      ) : (
        <>
          <SelectInput
            options={creditCardOptions}
            extractTitle={item => (item ? `**** **** **** ${item.label}` : '')}
            selectedOption={selectedCreditCard}
            label="Use existing card"
            placeholder="Select one"
            ariaLabelledby="credit-card-select"
            onSelect={setSelectedCreditCard}
            inputId="credit-card-select-id"
          />
          <BorderedButton text="Add new card" onClick={() => setShowNewCreditCardForm(true)} />
        </>
      )}
    </PaymentDetailsCreditCard>
  );
};

export const useNewCreditCardForm = (alwaysShowNewCreditCardForm: boolean) => {
  const [showNewCreditCardForm, setShowNewCreditCardForm] = useState<boolean>(true);

  if (alwaysShowNewCreditCardForm) {
    return {
      showNewCreditCardForm,
      setShowNewCreditCardForm: () => setShowNewCreditCardForm(true)
    };
  }

  return { showNewCreditCardForm, setShowNewCreditCardForm };
};

const ElementsContent = ({
  onStripeCardElementInitialized,
  onValidation,
  paymentOptional,
  onCreditCardChange
}: ElementsContentProps) => {
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement) onStripeCardElementInitialized(stripe, cardNumberElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements]);

  useEffect(() => {
    onValidation(cardNumberValid && cardExpiryValid && cardCvcValid);
  }, [onValidation, cardNumberValid, cardExpiryValid, cardCvcValid]);

  const options = {
    style: stripeInputStyle
  };

  const onElementChanged = (
    ev:
      | StripeContext['cardNumberChangeEvent']
      | StripeContext['cardExpiryChangeEvent']
      | StripeContext['cardCvcChangeEvent']
  ) => {
    const changedElementIsValid = !!(ev?.complete && !ev?.empty);

    switch (ev?.elementType) {
      case 'cardNumber':
        onCreditCardChange({ empty: ev?.empty });
        return setCardNumberValid(changedElementIsValid);
      case 'cardExpiry':
        return setCardExpiryValid(changedElementIsValid);
      case 'cardCvc':
        return setCardCvcValid(changedElementIsValid);
      default:
        return;
    }
  };

  return (
    <>
      <CardNumberContainer>
        Card number{paymentOptional ? ' (Optional)' : ''}
        <CardNumberElement options={options} onChange={onElementChanged} />
      </CardNumberContainer>

      <CardExpirationContainer>
        Expiration
        <CardExpiryElement options={options} onChange={onElementChanged} />
      </CardExpirationContainer>

      <CardCvcContainer>
        CVC
        <CardCvcElement options={options} onChange={onElementChanged} />
      </CardCvcContainer>
    </>
  );
};

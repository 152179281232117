import { useQuery } from '@apollo/client';

import useHomeMarket from 'hooks/useHomeMarket';

import { STATUS } from '../screens/InterstitialModal/InterstitialModal.graphql';
import {
  InterstitialStatusResponse,
  Status
} from '../screens/InterstitialModal/InterstitialModal.types';

export const useInterstitialStatus = (opts?: {
  skip?: boolean;
}): { status: Status | null; loading: boolean } => {
  const { id, vios } = useHomeMarket();
  const { data, loading } = useQuery<InterstitialStatusResponse>(STATUS, {
    skip: vios || opts?.skip
  });

  return {
    status: !data || !id ? null : { ...data.interstitialStatus },
    loading
  };
};

import React, { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { LongWordContainer } from 'components/v2/common.styled';
import { TextField } from 'components/v2/Form';
import { Heading, Text } from 'components/v2/Typography';
import { ReduxState } from 'kb-redux';
import { confirmUser, resendConfirmationCode } from 'kb-redux/user.redux';
import { ConfirmationError, ResendCodeStatus, utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import {
  ButtonWrapper,
  Container,
  Form,
  ResendButton,
  SubTitleContainer
} from './VerificationCode.styled';
import { Props } from './VerificationCode.types';

const { isEmailCodeValid } = utils;

export const VerificationCode = ({ onSuccess, email }: Props) => {
  const dispatch = useDispatch();
  const userConfirmationState = useSelector<ReduxState>(state => state.patient.confirmationState);
  const confirmationError = useSelector<ReduxState, ConfirmationError | null>(
    state => state.patient.confirmationError
  );
  const resendCode = useSelector<ReduxState, ResendCodeStatus | null>(
    state => state.patient.resendConfirmationCode
  );
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setSubmitting(false);
    if (userConfirmationState === 'confirmed') {
      analytics.track(analytics.EVENTS.ACCOUNT_VERIFICATION_SUCCEEDED);
      onSuccess ? onSuccess() : history.replace('/login');
    }

    if (confirmationError) {
      switch (confirmationError.type) {
        case 'InvalidCode':
          onError("Your code doesn't match. Please check again.");
          break;
        case 'ExpiredCode':
          onError('Your code has expired. Please click the link below to send a new code.');
          break;
        case 'Unknown':
        default:
          BugTracker.notify(confirmationError.type || '', 'Email address verification failed');
          onError("We're sorry, something went wrong. Please check your code and try again.");
          break;
      }
    }
    if (resendCode?.status && resendCode.status === 'error') {
      const error = resendCode.error;
      if (error === 'LimitExceededException' || error === 'TooManyRequestsException') {
        onError(
          `You have exceeded the limit for requesting a verification code. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
        );
      } else {
        BugTracker.notify(error || '', 'Resend verification code failed');
        onError(
          "We're sorry, we were unable to resend your code. Please check your email address and try again."
        );
      }
    }
  }, [userConfirmationState, confirmationError, resendCode, onSuccess, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    analytics.page(analytics.PAGES.VERIFICATION);
  }, []);

  const onError = (error: string) => {
    analytics.track(analytics.EVENTS.ACCOUNT_VERIFICATION_FAILED);
    showErrorToast(error);
  };

  const submitVerificationCode = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    analytics.track(analytics.EVENTS.ACCOUNT_VERIFICATION_CODE_SUBMITTED);
    dispatch(confirmUser(code, email));
  };

  const updateCode = (event: FormEvent<HTMLInputElement>) => {
    setCode(event.currentTarget.value);
  };

  const onResendCodeClick = () => {
    analytics.track(analytics.EVENTS.ACCOUNT_VERIFICATION_CODE_RESUBMITTED);
    email && dispatch(resendConfirmationCode(email));
  };

  const getResendText = (): string => {
    let text = 'Resend code';
    if (resendCode?.status === 'loading') {
      text = 'Resending code...';
    } else if (resendCode?.status === 'success') {
      text = 'Please check your email for a new code. Click here to resend it again';
    }

    return text;
  };

  return (
    <Container>
      {
        <SubTitleContainer>
          <div>
            <Heading tag="span" styledAs="h5">
              <Text tag="strong" fontStyle="medium">
                {'A verification code was sent to'}
              </Text>
            </Heading>
          </div>
          <LongWordContainer>
            <Heading tag="span" styledAs="h4">
              <Text tag="span" fontStyle="regular">
                {email}
              </Text>
            </Heading>
          </LongWordContainer>
          <div>
            <Text tag="span" size="sm" fontStyle="regular">
              {'It may take several minutes to receive this verification code.'}
            </Text>
          </div>
        </SubTitleContainer>
      }
      <Form onSubmit={submitVerificationCode}>
        <TextField
          id="verificationCodeInput"
          label="VERIFICATION CODE"
          placeholder="Enter emailed code"
          type="number"
          value={code}
          onChange={updateCode}
          spellCheck={false}
          hideArrowsForNumberInput={true}
        />

        <ButtonWrapper>
          <Button
            label={'VERIFY AND CONTINUE'}
            category="primary"
            isDisabled={submitting || !isEmailCodeValid(code)}
            fullWidth={true}
          />
        </ButtonWrapper>
        {
          <ResendButton onClick={onResendCodeClick}>
            <Text tag="p" size="md" fontStyle="medium">
              {getResendText()}
            </Text>
          </ResendButton>
        }
      </Form>
    </Container>
  );
};

import { Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { AppointmentTimeProps, ContainerProps } from './AppointmentCard.types';

const { deprecatedColors, colors, fonts } = themes;

export const CardContainer = styled.div`
  padding: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  height: 100%;
  z-index: 1;

  ${({ isFuture }: ContainerProps) => `
    background-color: ${isFuture ? 'white' : deprecatedColors.grayLight};
    `}
`;

export const AppointmentTime = styled.div`
  ${fonts.DomaineDisplay};
  font-size: 36px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: ${({ hasStepper }: AppointmentTimeProps) => (hasStepper ? '35px' : '0px')};
`;

export const AppointmentLocation = styled.div`
  ${fonts.FoundersGrotesk};
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

export const ClinicName = styled.span`
  font-weight: bold;
`;

export const Address = styled.span``;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ConsultInstructions = styled('div')`
  max-width: 270px;
  text-align: center;
  ${fonts.FoundersGrotesk}
  font-size: 14px;
  font-weight: 300;
  color: ${colors.neutral.primaryNavy};
  margin-bottom: 12px;
`;

export const VideoConsult = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 135px;
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
  background: ${colors.yellow.lighterYellow};
`;

export const PhoneConsult = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 9px;
  padding-top: 20px;
  background: ${colors.yellow.lighterYellow};
`;

export const FutureActionsWrapper = styled.div`
  align-self: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  > button {
    margin: 28px auto 0;
  }
`;

export const AppointmentName = styled.span`
  display: block;
  ${fonts.Gotham}
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const ProviderInfo = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};

  display: flex;
  align-items: center;
  margin: 18px 0 12px 0;
`;

export const ProviderName = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};

  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 18px;
  line-height: 18px;
  margin: 0 0 0 8px;
`;

export const ProviderLabel = styled.div`
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 6px 0 0;
`;

export const RescheduledText = styled(Text)`
  margin-top: 1.5em;
  text-align: center;
`;
